import React from "react"

import SEO from "../components/seo"
import FourOhFour from "src/views/404"

const NotFoundPage = () => (
  <>
    <SEO title="Nie znaleziono strony" />
    <FourOhFour />
  </>
)

export default NotFoundPage

import styled from "styled-components"
import { isMobile } from "react-device-detect"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 10%;
  ${isMobile &&
  `
    padding-top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    
  `}
`
export const Header = styled.h1`
  color: #ffffff;
  font-family: Open Sans;
  font-size: 3.75rem;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.25rem;
  text-align: center;
  position: relative;
  margin-bottom: 10rem;
  &::before {
    content: "";
    border-bottom: 1px solid #fff;
    width: 100%;
    position: absolute;
    left: 55%;
    top: 50%;
  }
  &::after {
    content: "";
    border-bottom: 1px solid #fff;
    width: 100%;
    position: absolute;
    right: 55%;
    top: 50%;
  }
  ${isMobile &&
  `
    &::before {
      left: 130%;
      width: 100vw;
    }
    &::after {
      width: 100vw;
      right: 130%;
    }
  `}
`
export const Title = styled.h2`
  color: #ffffff;
  font-family: "Abril Fatface";
  font-size: 4.25rem;
  font-weight: 400;
  line-height: 5rem;
  text-align: left;
  margin-bottom: 5rem;
  margin-left: 50%;
  transform: translateX(-30%);
  ${isMobile &&
  `
    transform: none;
    margin: 0;
    margin-left: 5rem;
  width: 100%;

  `}
`
export const Text = styled.p`
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 1.32rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 50%;
  transform: translateX(-30%);
  ${isMobile &&
  `
  width: 100%;
    transform: none;
    margin: 5rem;
    margin-right: 0;

    font-size: 2rem;
  `}
`

import React from "react"
import { Wrapper, Header, Title, Text } from "./styles"

const FourOhFour = () => {
  return (
    <Wrapper>
      <Header>404</Header>
      <Title>
        Przepraszamy,
        <br /> coś poszło nie tak.
      </Title>
      <Text style={{ marginBottom: "6rem" }}>
        Materiał mógł zostać przeniesiony lub usunięty.
      </Text>
      {/* <Text>Pobierz aplikację Polskiego Radia</Text> */}
    </Wrapper>
  )
}

export default FourOhFour
